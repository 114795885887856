@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    position: relative;
    color: white;
    height: 100%;
    /*Note, you can change the color to your choice depending on your 
    image and what color blends with it*/
}

.container::after {
    content: "";
    opacity: 0.8;
    background-image:  url("./bg.jpg");
    background-blend-mode: luminosity;
    /* also change the blend mode to what suits you, from darken, to other 
    many options as you deem fit*/
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    background-position: center;
    filter: blur(4px);
}
.hover-trigger .hover-target {
    transform: scale(1.0);
}

.hover-trigger:hover .hover-target {
    transform: scale(1.1);
}